var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c(
            "v-tabs",
            {
              attrs: { light: "" },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c("v-tabs-slider"),
              _c("v-tab", { attrs: { href: "#addUser" } }, [
                _vm._v(" Benutzer einladen "),
              ]),
              _c("v-tab", { attrs: { href: "#listUserByName" } }, [
                _vm._v(" Benutzer nach Name "),
              ]),
              _c("v-tab", { attrs: { href: "#listUserByCompany" } }, [
                _vm._v(" Benutzer nach Firma "),
              ]),
            ],
            1
          ),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c(
                "v-tab-item",
                { key: 1, attrs: { value: "addUser" } },
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-col",
                            { key: _vm.componentKey, attrs: { md: "9" } },
                            [
                              _vm._v(
                                ' Um einen neuen Anwender hinzuzufügen, einfach eine E-Mail-Adresse eingeben, zugehörige Firma (oder Firmen) angeben und auf "Absenden" '
                              ),
                              _c(
                                "v-form",
                                { ref: "form" },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "E-Mail-Adresse",
                                      rules: _vm.emailRules,
                                    },
                                    model: {
                                      value: _vm.email,
                                      callback: function ($$v) {
                                        _vm.email = $$v
                                      },
                                      expression: "email",
                                    },
                                  }),
                                  _c("v-autocomplete", {
                                    attrs: {
                                      items: _vm.firmen,
                                      outlined: "",
                                      dense: "",
                                      chips: "",
                                      "small-chips": "",
                                      label: "Firmen",
                                      multiple: "",
                                      "item-text": "name",
                                      "return-object": "",
                                    },
                                    model: {
                                      value: _vm.ausgewaehlte_firmen,
                                      callback: function ($$v) {
                                        _vm.ausgewaehlte_firmen = $$v
                                      },
                                      expression: "ausgewaehlte_firmen",
                                    },
                                  }),
                                  _c("v-select", {
                                    attrs: {
                                      label: "Berechtigungsstufe",
                                      items:
                                        this.$store.state.roles.allRolesArray,
                                      "item-text": "name",
                                      hint: _vm.stufe.info,
                                      "return-object": "",
                                      "single-line": "",
                                    },
                                    model: {
                                      value: _vm.stufe,
                                      callback: function ($$v) {
                                        _vm.stufe = $$v
                                      },
                                      expression: "stufe",
                                    },
                                  }),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { elevation: "2" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.sendInvitation()
                                        },
                                      },
                                    },
                                    [_vm._v("Anwender einladen")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-snackbar",
                                {
                                  attrs: {
                                    color: "success",
                                    timeout: _vm.timeout,
                                    top: _vm.top,
                                  },
                                  model: {
                                    value: _vm.showSuccess,
                                    callback: function ($$v) {
                                      _vm.showSuccess = $$v
                                    },
                                    expression: "showSuccess",
                                  },
                                },
                                [_vm._v("Einladung versendet")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-card-text",
                        [
                          _vm._v(
                            " Folgende Einladungen sind noch ausstehend: "
                          ),
                          _c("v-data-table", {
                            staticClass: "elevation-1",
                            attrs: {
                              headers: _vm.headersListEinladungen,
                              items:
                                this.$store.state.einladungen
                                  .allEinladungenArray,
                              search: _vm.search,
                              "items-per-page": 10,
                            },
                            scopedSlots: _vm._u(
                              [
                                _vm._l(
                                  _vm.headersListEinladungen.filter((header) =>
                                    header.hasOwnProperty("formatter")
                                  ),
                                  function (header) {
                                    return {
                                      key: `item.${header.value}`,
                                      fn: function ({ value }) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(header.formatter(value)) +
                                              " "
                                          ),
                                        ]
                                      },
                                    }
                                  }
                                ),
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-tab-item",
                { key: 2, attrs: { value: "listUserByName" } },
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-title",
                        [
                          _c("v-spacer"),
                          _c("v-text-field", {
                            attrs: {
                              "append-icon": "mdi-magnify",
                              label: "Suche",
                              "single-line": "",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.search,
                              callback: function ($$v) {
                                _vm.search = $$v
                              },
                              expression: "search",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c("v-data-table", {
                            staticClass: "elevation-1",
                            attrs: {
                              headers: _vm.headersListByUsername,
                              items: this.$store.state.users.allUserArray,
                              search: _vm.search,
                              "items-per-page": 10,
                            },
                            scopedSlots: _vm._u(
                              [
                                _vm._l(
                                  _vm.headersListByUsername.filter((header) =>
                                    header.hasOwnProperty("formatter")
                                  ),
                                  function (header) {
                                    return {
                                      key: `item.${header.value}`,
                                      fn: function ({ value }) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(header.formatter(value)) +
                                              " "
                                          ),
                                        ]
                                      },
                                    }
                                  }
                                ),
                                {
                                  key: "item.actions",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-2",
                                          attrs: { small: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.editItem(item)
                                            },
                                          },
                                        },
                                        [_vm._v(" mdi-pencil ")]
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { small: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteItem(item)
                                            },
                                          },
                                        },
                                        [_vm._v(" mdi-delete ")]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "top",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-dialog",
                                        {
                                          attrs: { "max-width": "800px" },
                                          model: {
                                            value: _vm.dialog,
                                            callback: function ($$v) {
                                              _vm.dialog = $$v
                                            },
                                            expression: "dialog",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            [
                                              _c("v-card-title", [
                                                _c(
                                                  "span",
                                                  { staticClass: "text-h5" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.editedItem.name
                                                      ) +
                                                        " " +
                                                        _vm._s(
                                                          _vm.editedItem
                                                            .family_name
                                                        )
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _c("v-divider"),
                                              _c("v-card-title", [
                                                _vm._v("Firma hinzufügen"),
                                              ]),
                                              _c(
                                                "v-card-text",
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c("v-autocomplete", {
                                                        attrs: {
                                                          items: _vm.firmen,
                                                          outlined: "",
                                                          dense: "",
                                                          chips: "",
                                                          "small-chips": "",
                                                          label: "Firmen",
                                                          multiple: "",
                                                          "item-text": "name",
                                                          "return-object": "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ausgewaehlte_firmenUserToCompany,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.ausgewaehlte_firmenUserToCompany =
                                                              $$v
                                                          },
                                                          expression:
                                                            "ausgewaehlte_firmenUserToCompany",
                                                        },
                                                      }),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            color: "pink",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                "x-large": "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.addUserToFirmen(
                                                                      _vm
                                                                        .editedItem
                                                                        .id,
                                                                      _vm.ausgewaehlte_firmenUserToCompany
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " mdi-arrow-right-circle-outline "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("v-divider"),
                                              _c("v-card-title", [
                                                _vm._v("Firma entfernen"),
                                              ]),
                                              _c(
                                                "v-card-text",
                                                [
                                                  _c(
                                                    "v-simple-table",
                                                    [
                                                      _c("tr", [
                                                        _c("th", [
                                                          _vm._v(
                                                            "Geschaeftspartner-Nr."
                                                          ),
                                                        ]),
                                                        _c("th", [
                                                          _vm._v("Firma"),
                                                        ]),
                                                      ]),
                                                      _vm._l(
                                                        _vm.editedItem.firmen,
                                                        function (
                                                          firmennummer
                                                        ) {
                                                          return _c(
                                                            "tr",
                                                            {
                                                              key: firmennummer,
                                                            },
                                                            [
                                                              _c("td", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    firmennummer
                                                                  )
                                                                ),
                                                              ]),
                                                              _c("td", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getFirmenName(
                                                                      firmennummer
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                              _c(
                                                                "td",
                                                                [
                                                                  _vm.editedItem
                                                                    .firmen
                                                                    .length > 1
                                                                    ? _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              small:
                                                                                "",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.deleteFromCompanyConfirm(
                                                                                  _vm
                                                                                    .editedItem
                                                                                    .id,
                                                                                  firmennummer
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " mdi-delete "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("v-divider"),
                                              _c("v-card-title", [
                                                _vm._v("Userlevel ändern"),
                                              ]),
                                              _c(
                                                "v-card-text",
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c("v-autocomplete", {
                                                        attrs: {
                                                          items:
                                                            _vm.$store.state
                                                              .roles
                                                              .allRolesArray,
                                                          outlined: "",
                                                          dense: "",
                                                          chips: "",
                                                          "small-chips": "",
                                                          label: "Firmen",
                                                          single: "",
                                                          "item-text": "name",
                                                          "item-value": "level",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ausgewaehlter_Userlevel,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.ausgewaehlter_Userlevel =
                                                              $$v
                                                          },
                                                          expression:
                                                            "ausgewaehlter_Userlevel",
                                                        },
                                                      }),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            color: "pink",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                "x-large": "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.setUserlevel(
                                                                      _vm
                                                                        .editedItem
                                                                        .id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " mdi-arrow-right-circle-outline "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-card-actions",
                                                [
                                                  _c("v-spacer"),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "blue darken-1",
                                                        text: "",
                                                      },
                                                      on: { click: _vm.close },
                                                    },
                                                    [_vm._v(" Schließen ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-dialog",
                                        {
                                          attrs: { "max-width": "800px" },
                                          model: {
                                            value: _vm.dialogDelete,
                                            callback: function ($$v) {
                                              _vm.dialogDelete = $$v
                                            },
                                            expression: "dialogDelete",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            [
                                              _c(
                                                "v-card-title",
                                                { staticClass: "text-h5" },
                                                [
                                                  _vm._v(
                                                    'Benutzer "' +
                                                      _vm._s(
                                                        _vm.editedItem.name
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.editedItem
                                                          .family_name
                                                      ) +
                                                      '" wirklich löschen?'
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-card-actions",
                                                [
                                                  _c("v-spacer"),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "blue darken-1",
                                                        text: "",
                                                      },
                                                      on: {
                                                        click: _vm.closeDelete,
                                                      },
                                                    },
                                                    [_vm._v("abbrechen")]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "blue darken-1",
                                                        text: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteItemConfirm(
                                                            _vm.editedItem.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("OK")]
                                                  ),
                                                  _c("v-spacer"),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-tab-item",
                { key: 3, attrs: { value: "listUserByCompany" } },
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-title",
                        [
                          _c("v-spacer"),
                          _c("v-text-field", {
                            attrs: {
                              "append-icon": "mdi-magnify",
                              label: "Suche",
                              "single-line": "",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.searchCompany,
                              callback: function ($$v) {
                                _vm.searchCompany = $$v
                              },
                              expression: "searchCompany",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c("v-data-table", {
                            staticClass: "elevation-1",
                            attrs: {
                              headers: _vm.headersListByCompany,
                              items:
                                this.$store.state.companys.allCompaniesArray,
                              search: _vm.searchCompany,
                              "items-per-page": 10,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "item.fullAdress",
                                fn: function ({ item }) {
                                  return [
                                    _vm._v(
                                      _vm._s(item.strasse) +
                                        " " +
                                        _vm._s(item.hausnummer) +
                                        _vm._s(item.hausnummer_zusatz) +
                                        ", " +
                                        _vm._s(item.plz) +
                                        " " +
                                        _vm._s(item.ort)
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "item.attachedUsers",
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-2",
                                        attrs: { small: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editAttachedUsers(item)
                                          },
                                        },
                                      },
                                      [_vm._v(" mdi-magnify ")]
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "top",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-dialog",
                                      {
                                        attrs: { "max-width": "800px" },
                                        model: {
                                          value: _vm.dialogAttachUser,
                                          callback: function ($$v) {
                                            _vm.dialogAttachUser = $$v
                                          },
                                          expression: "dialogAttachUser",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          [
                                            _c("v-card-title", [
                                              _c(
                                                "span",
                                                { staticClass: "text-h5" },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.editedItem.id) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.editedItem.name
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c("v-divider"),
                                            _c("v-card-title", [
                                              _vm._v("Mitarbeiter hinzufügen"),
                                            ]),
                                            _c(
                                              "v-card-text",
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c("v-autocomplete", {
                                                      attrs: {
                                                        items:
                                                          _vm.$store.state.users
                                                            .allUserArray,
                                                        outlined: "",
                                                        dense: "",
                                                        chips: "",
                                                        "small-chips": "",
                                                        label: "User",
                                                        multiple: "",
                                                        "item-value": (item) =>
                                                          `${item.id}`,
                                                        "item-text": (item) =>
                                                          `${item.name} ${item.family_name} (${item.email})`,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.ausgewaehlte_BenutzerUserToCompany,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.ausgewaehlte_BenutzerUserToCompany =
                                                            $$v
                                                        },
                                                        expression:
                                                          "ausgewaehlte_BenutzerUserToCompany",
                                                      },
                                                    }),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          color: "pink",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              "x-large": "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.addUserToFirmen(
                                                                  _vm.ausgewaehlte_BenutzerUserToCompany,
                                                                  [
                                                                    _vm.editedItem,
                                                                  ]
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " mdi-arrow-right-circle-outline "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-card-actions",
                                              [
                                                _c("v-spacer"),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      color: "blue darken-1",
                                                      text: "",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.closeAttachUser,
                                                    },
                                                  },
                                                  [_vm._v(" Schließen ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }